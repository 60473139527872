import Loader from "@/Components/LoaderMin.jsx";
export default ({ blok }) => {
    return blok.BackgroundMobile ? (
        <section>
            <div className="banner">
                <div className="banner-bgc">
	                {blok.BackgroundMobile && blok.BackgroundMobile.filename && <img rel="preload" className="banner-bgc-mobile" src={blok.BackgroundMobile.filename} alt={blok.background.alt} />}
                    <img rel="preload" src={blok.background.filename} alt={blok.background.alt} />
                </div>
                <div className="banner-info">
                    <h1 className="banner-info-title">{blok.title}</h1>
                    <p className="banner-info-text">
                        {blok.description}
                    </p>
                    <div className="banner-info-btns">
                        {blok.links.map((link) => (
                            <a key={link._uid} href={link.link.url} className="banner-info-btn">
                                {link.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    ) :  <Loader/>
}
