import {useState,useEffect} from "react"
import {formatCurrency} from '@/Util/Currency'
import {optimizeImage} from '@/Util/Images'
import {usePage} from '@inertiajs/react'

export default ({ style }) => {
    const {settings} = usePage().props
	const frame = style.frame
	const brand = style.brand

	function imageExists(url, callback) {
		let img = new Image();
		img.onload = function() { callback(true); };
		img.onerror = function() { callback(false); };
		img.src = url;
	}

	const [imageUrl, setImageUrl] = useState(optimizeImage(style.image_url, 600));

	useEffect(() => {
		const img = imageUrl;

		if (!img) return;

		imageExists(img, function(exists) {
			if (!exists) {
				setImageUrl('/img/stock.svg')
			}
		});
	}, []);

    const sale_price = style.sale_price
    let regular_price = style.regular_price

    if (style.msrp) {
        regular_price = style.msrp
    } else if (frame.frame_msrp) {
        regular_price = frame.frame_msrp
    }

    if (!regular_price || regular_price <= sale_price) {
        regular_price = null
    }

	return (
		<a className="glasses-slide glasses-item multiSlide" href={style.url}>
			<div className="glasses-item-img">
				<img src={imageUrl} alt={frame.long_name}/>
			</div>
            {settings.sale.active && regular_price && style.sale_pct >= 10 && (
                <div className="product-tag">{settings.sale.title}</div>
            )}
			<div className="product-item-name">{brand.brand_name}</div>
			<div className="product-item-vendor">{frame.frame_number}</div>
			<div className="product-item-price">
				{regular_price && style.sale_pct >= 5 ? (
					<>
						<strong className="product-item-actual red">{formatCurrency(sale_price, 2)}</strong>
						<span className="product-item-old">{formatCurrency(regular_price, 2)}</span>
                        {style.sale_pct >= 10 && (
                            <span className="product-item-discount">{style.sale_pct}% off</span>
                        )}
					</>
				) : (
					<span>{formatCurrency(style.sale_price, 2)}</span>
				)}
			</div>
		</a>
	)
}
