import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    // TODO: Make editable in Storyblok, link to brand pages

    return (
        <section className="section-full" {...storyblokEditable(blok)}>
            <div className="logotips">
                <h5 className="logotips-title">Shop by Brand</h5>
                <ul className="logotips-list">
                    <li className="logotips-item">
                        <a href="/glasses/prada-designer-eyewear/">
                            <img src="/img/prada-logo-svg.svg" alt="Prada" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/versace-designer-eyewear/">
                            <img src="/img/versace-logo-svg.svg" alt="Versace" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/cartier-eyewear/">
                            <img src="/img/cariter-logo-svg.svg" alt="Cartier" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/tom-ford-italian-designer-eyewear/">
                            <img src="/img/tomford-logo-svg.svg" alt="Tom Ford" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/gucci-eyewear/">
                            <img src="/img/gucci-logo-svg.svg" alt="Gucci" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/silhouette-rimless-eyewear/">
                            <img src="/img/silhouette-logo-svg.svg" alt="Silhouette" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/persol-eyewear/">
                            <img src="/img/persol-logo-svg.svg" alt="Persol" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/alain-mikli-designer-eyewear/">
                            <img src="/img/alainmikli-logo-svg.svg" alt="Alain Mikli" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/balenciaga-eyewear/">
                            <img src="/img/balenciaga-logo-svg.svg" alt="Balenciaga" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/maui-jim-polarizedplus-eyewear/">
                            <img src="/img/maui-jim-logo-svg.svg" alt="Maui Jim" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/dolce-gabbana-designer-eyewear/">
                            <img src="/img/dolce-gabbana-logo-svg.svg" alt="Dolce &amp; Gabbana" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/nike-sports-eyewear/">
                            <img src="/img/nike-logo-svg.svg" alt="Nike" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/dita-eyewear/">
                            <img src="/img/dita-logo-svg.svg" alt="DITA" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/burberry-designer-eyewear/">
                            <img src="/img/burberry-logo-svg.svg" alt="Burberry" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/bottega-veneta-eyewear/">
                            <img src="/img/bottega-veneta-logo-svg.svg" alt="Bottega Veneta" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/ray-ban-sunglasses/">
                            <img src="/img/ray-ban-logo-svg.svg" alt="Ray-Ban" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/alexander-mcqueen-eyewear/">
                            <img src="/img/aleander-mcqueen-logo-svg.svg" alt="Alexander McQueen" />
                        </a>
                    </li>
                    <li className="logotips-item">
                        <a href="/glasses/oakley-polarized-sports-eyewear/">
                            <img src="/img/oakley-logo-svg.svg" alt="Oakley" />
                        </a>
                    </li>
                </ul>
                <div className="banner-block-info-btns">
                    <a href="/brands/" className="banner-block-info-btn">View All Brands</a>
                </div>
            </div>
        </section>
    )
}
