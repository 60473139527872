export default ({ video }) => {
    return (
        <video
            className="block mx-auto"
            src={video.filename || video.src}
            title={video.title}
            autoPlay={true}
        />
    )
}
