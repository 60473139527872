import { storyblokEditable } from '@storyblok/react'
import Content from '@/Components/Content'

export default ({ blok }) => {
    return (
        <section className="stories-preview-title"
             {...storyblokEditable(blok)}
        >
            <div className="w-5/6 md:max-w-5xl text-[16px] md:text-[22px] mx-auto text-center font-normal leading-relaxed mb-20 md:mb-8 text-black">
                <Content content={blok.text} />
            </div>
        </section>
    );
};
