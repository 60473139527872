import { storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <div className="shop-activity-item multiSlide" {...storyblokEditable(blok)}>
            <a className="shop-activity-item-img" href={blok.link.url}>
                <img src={blok.image.filename} alt={blok.image.alt} />
            </a>

            <a className="shop-activity-item-title" href={blok.link.url}>{blok.title}</a>

            <span className="shop-activity-item-text">
                {blok.description}
            </span>

            <a className="shop-activity-item-link" href={blok.link.url}>
                {blok.link_text}
            </a>
        </div>
    )
}
