import { usePage } from '@inertiajs/react'

export const optimizeImage = (url, width, quality = 80, format = 'auto') => {
    const { settings } = usePage().props

    const optimizeUrl = settings.urls.image_optimize

    if (optimizeUrl) {
        // https://www.coolframes.com/cdn-cgi/image/width=199,quality=80/
        return optimizeUrl + `width=${width},quality=${quality},format=${format}/` + url
    }

    return url
}
