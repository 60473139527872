import { StoryblokComponent } from '@storyblok/react'
import Image from '@/Components/Image'

const Content = ({ content }) => {
	const textOutput = (content, k) => {
		if (content.type === 'text') {
			if(content.marks){
				let fontSize,link;
				let cls = '';
				for (let i = 0; i < content.marks.length; i++) {
					let item = content.marks[i];
					if (item.type == 'styled') fontSize = item.attrs.class;
					if (item.type == 'link') {
						link = item.attrs.href;
						cls += " stories-text-link";
					}
					if (item.type == 'bold') cls += ' bold';
					if (item.type == 'italic') cls += ' italic';
				}
				if (link) {
					return (
						<a key={k} className={cls} href={link} style={fontSize && {fontSize:fontSize}}>{content.text}</a>
					)
				} else {
					return (
						<span key={k} className={cls} style={fontSize && {fontSize:fontSize}}>{content.text}</span>
					)
				}
			} else {
				return (
					<span key={k}>{content.text}</span>
				)
			}
		} else if (content.type === 'image') {
			if (content.marks) {
				let link = '';
				for (let i = 0; i < content.marks.length; i++) {
					let item = content.marks[i]
					if (item.type == 'link') link = item.attrs.href;
				}
				return (
					<p key={k} className="my-8">
						<a href={link}><Image image={content.attrs} /></a>
					</p>
				)
			} else {
				return (
					<p key={k} className="my-8">
						<Image image={content.attrs} />
					</p>
				)
			}
		}
	}

  if (content.type === 'doc') {
    return content.content?.map((content, i) => (
      <Content key={i} content={content} />
    ))
  } else if (content.type === 'ordered_list') {
      return (
          <ol className="list-decimal pl-8">
              {content.content?.map((content, i) => (
                  <Content key={i} content={content} />
              ))}
          </ol>
      )
  } else if (content.type === 'list_item') {
      return (
          <li>
              {content.content?.map((content, i) => (
                  <Content key={i} content={content} />
              ))}
          </li>
      )
  } else if (content.type == 'paragraph') {
  	if (!content.content || !content.content.length) return <></>
  	let type = content.content[0].type;
	  let parts = [{type:type,content:[]}];
  	let index = 0;
	  for (let i = 0; i < content.content.length; i++) {
		  let item = content.content[i];
		  if (item.type !== type) {
		  	type = item.type;
		  	index += 1;
		  	parts.push({type:type,content:[]});
		  }
		  parts[index].content.push(item);
	  }

		return (<>{
			parts.map((part, k)=>{
				return (<div key={k} className={part.type !== 'image' ? "stories-paragraph" : ""}>
					{part.content.map((item, i) => {
						return textOutput(item, i)
					})}
				</div>)
			})
		}</>)
  } else if (content.type === 'blok') {
    return content.attrs.body.map((blok) => (
      <StoryblokComponent blok={blok} key={blok._uid} />
    ))
  }

  return ''
}

export default Content
