import { storyblokEditable } from '@storyblok/react'
import Story from '@/Components/Blog/Story'

export default ({ blok }) => {
  return (
  	<>
		  <section className="section-full">
			  <h2 className="story-subtitle">{blok.component.replaceAll('-',' ')}</h2>
		  </section>
		  <div className="my-8 flex gap-4 max-w-full" {...storyblokEditable(blok)}>
		    <section className="section-full">
		      <Story story={blok.story} size="featured" />
		    </section>
		  </div>
	  </>
  );
};
