import { storyblokEditable } from '@storyblok/react'
import Content from '@/Components/Content'

export default ({ blok }) => {
    return (
        <div
            {...storyblokEditable(blok)}
        >
            <h2 className="text-center lg:text-left text-2.5xl lg:text-4xl leading-tight mb-6 lg:mb-8" dangerouslySetInnerHTML={{
                __html: blok.heading
            }}></h2>
            <div className="text-sm lg:text-base text-neutral-900 space-y-4">
                <Content content={blok.body} />
            </div>
        </div>
    )
};
