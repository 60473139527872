export const formatCurrency = (price, decimals = 0, strip_zero = true) => {
    if (! price) {
        return
    }

    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals
    }

    const formatter = new Intl.NumberFormat('en-US', options)

    let formatted = formatter.format(price)

    // 18.4 -> 18.40, but 18.0 -> 18
    if (decimals > 0 && strip_zero) {
        formatted = formatted.replace(/\D00$/, '')
    }

    return formatted
}
