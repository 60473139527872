import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

const ContentPage = ({ blok }) => {
    const className = blok.class || 'home-page'

    return (
        <main className={className} {...storyblokEditable(blok)} key={blok._uid}>
            {blok.content.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
        </main>
    );
};

export default ContentPage;
