import {useEffect, useState, useRef} from 'react'
import {storyblokEditable} from '@storyblok/react'
import {useSearch} from '@/Components/Search/SearchContext'
import GlassesSlide from './GlassesSlide'

export default ({blok}) => {
	const searchClient = useSearch()

	const collections = (blok.type === 'Sunglasses') ? [
        {
            name: 'New Arrivals: Sunglasses',
            title: 'New Arrivals',
            url: '/shop/new-arrivals-sunglasses'
        },
        {
            name: 'Trending Styles Sunglasses',
            title: 'Trending Styles',
            url: '/shop/trending-styles-sunglasses'
        },
        {
            name: 'Best Selling Sunglasses',
            title: 'Best Sellers',
            url: '/shop/best-selling-sunglasses'
        },
    ] : [
		{
			name: 'New Arrivals: Eyeglasses',
            title: 'New Arrivals',
            url: '/shop/new-arrivals-eyeglasses'
		},
		{
			name: 'Trending Styles Eyeglasses',
            title: 'Trending Styles',
            url: '/shop/trending-styles-eyeglasses'
		},
		{
			name: 'Best Selling Eyeglasses',
            title: 'Best Sellers',
            url: '/shop/best-sellers-eyeglasses'
		},
	];

	const [activeCollection, setActiveCollection] = useState(collections[0]);
	const [styles, setStyles] = useState([]);

	const index = searchClient.initIndex('frames_styles');
	const slider = useRef(null);

	useEffect(() => {
		index.search('', {
			filters: `frame.type_name:${blok.type} AND frame.categories:"${activeCollection.name}"`,
			distinct: true,
			hitsPerPage: 15,
		}).then(({hits}) => {
			setStyles(hits)
		})
	}, [activeCollection])

	function getCatName(title) {
		if (title) {
			return title.toLowerCase();
		}
	}

	const [isActive,setIsActive] = useState(false);
	const [isMobile,setIsMobile] = useState(window.innerWidth < 1000);
	const handleResize = () => {
		setIsMobile(window.innerWidth < 1000);
	};
	useEffect(() => {
		window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
  }, []);
	useEffect(() => {
		if (!styles || !styles.length) return;
		let totalWidth = 0;
		for (let i = 0; i < styles.length; i++) {
			totalWidth += isMobile ? 252 : 334;
		}
		if (totalWidth > slider.current.clientWidth) setIsActive(true)
	}, [styles]);

	return (
		<section className={"glasses-slider multiSlider" + (isActive ? ' active' : '')} {...storyblokEditable(blok)}>
			<h3 className="glasses-title">{blok.title}</h3>
			<div className="home-slider">
				<div className="home-slider-top">
					<div className="home-slider-category">
						{collections.map((collection, i) => (
							<button
								key={i}
								className={"home-slider-category-btn " + (activeCollection.name === collection.name ? 'active' : '')}
								onClick={() => {
									setActiveCollection(collection);
									slider.current.scrollTo(0,0);
								}}>
								{collection.title}
							</button>
						))}
					</div>
					<div className="home-slider-control multiSliderControl">
						<a className="home-slider-category-btn active" href={activeCollection.url}>View All</a>
						<button className="home-slider-control-btn prev" disabled>
							<svg>
								<use xlinkHref="#slider-prev-black"></use>
							</svg>
							<svg>
								<use xlinkHref="#slider-prev"></use>
							</svg>
						</button>
						<button className="home-slider-control-btn next">
							<svg>
								<use xlinkHref="#slider-next-black"></use>
							</svg>
							<svg>
								<use xlinkHref="#slider-next"></use>
							</svg>
						</button>
					</div>
				</div>
				<div className="glasses-slides multiSlides" ref={slider}>
					{styles.map((hit,index) => {
						return <GlassesSlide key={hit.id} style={hit}/>
					})}
				</div>
				<div className="home-slider-indicator"></div>
			</div>
			<a href={activeCollection.url} className="home-slider-button">More {activeCollection.title}</a>
		</section>
	)
}
