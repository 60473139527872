import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <div className="gray-col" {...storyblokEditable(blok)}>
            {blok.blocks.map((block) => (
                <StoryblokComponent blok={block} key={block._uid} />
            ))}
        </div>
    )
}
