import { storyblokEditable } from '@storyblok/react'
import {Disclosure} from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/20/solid'
import Content from '@/Components/Content'

export default ({ blok }) => {
    return (
        <div className="py-10"
             {...storyblokEditable(blok)}
        >
            <h2 className="text-center mb-10 lg:mb-20 text-neutral-800 text-4xl font-medium">{blok.heading}</h2>

            <div>
                {blok.questions.map(faq => (
                    <Disclosure key={faq._uid} as="div" className="">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="block w-full text-left text-lg font-bold text-zinc-800 border-none outline-none ring-0">
                                    <div className='flex w-full items-center px-2.5 py-3.5 lg:py-6 justify-between border-b-neutral-200 outline-none border-b'>
                                        <span>{faq.question}</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? '' : 'transform rotate-180'
                                            } w-6 h-6 lg:h-8 lg:w-8 text-black`}
                                        />
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="border-b-neutral-200 px-2.5 py-4 text-xs lg:text-base text-gray-600">
                                    <Content content={faq.answer} />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </div>
        </div>
    )
};
