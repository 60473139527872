export default ({ image }) => {
    return (
        <img
            className="block mx-auto"
            src={image.filename || image.src}
            title={image.title}
            alt={image.alt}
        />
    )
}
