import { storyblokEditable } from '@storyblok/react'
import Story from '@/Components/Blog/Story'

export default ({ blok, title = "Cool Frames Stories" }) => {
    return (
        <section className="related related-home" {...storyblokEditable(blok)}>
	        <div className="home-slider-top">
		        <h4 className="related-title">{title}</h4>
	        </div>

	        <section className="section-full">
		        <div className="blogs-grid">
			        {blok.stories.map(s => (
				        <Story key={s.id} story={s} />
			        ))}
		        </div>
	        </section>
        </section>
    )
}
