import Date from '@/Components/Date'
import Image from '@/Components/Image'

export default ({ story, size }) => {
  const isFeatured = size === 'featured'
  const image = isFeatured ? story.content.featured_image : story.content.thumbnail;
  return (
    <div>
      {story.content.thumbnail && (
        <a href={'/' + story.full_slug} className={isFeatured ? "" : "related-more-image"}><Image image={image} /></a>
      )}
      <div className={isFeatured ? 'max-w-2xl mx-auto text-center mb-12' : 'related-more-info'}>
        <div className="text-sm pt-8 pb-6">
          {story.content.type ? (
            <>
              <span className="text-red-600">{story.content.type}</span>
              <span className="text-4xs text-neutral-400 font-thin mx-4 md:mx-2">&bull;</span>
            </>
          ) : null}
          <span className="text-neutral-600">
	          <Date date={story.content.date}/>
	        </span>
        </div>
        <a href={'/' + story.full_slug} className={(isFeatured ? 'mx-auto text-3xl font-medium text-black leading-10 md:leading-relaxed block mb-6 md:mb-4' : 'stories-more-title')}>{story.content.title}</a>
        <a href={'/' + story.full_slug} className={(isFeatured ? 'text-lg' : 'text-sm') + " related-read-more"}>Read More</a>
      </div>
    </div>
  )
}
