import { createContext, useContext, useReducer } from 'react'

export const SearchContext = createContext(null)
export const SearchDispatchContext = createContext(null)

export const SearchProvider = ({ initial = [], children }) => {
    const [ search, dispatch ] = useReducer(searchReducer, initial)

    return (
        <SearchContext.Provider value={search}>
            <SearchDispatchContext.Provider value={dispatch}>
                {children}
            </SearchDispatchContext.Provider>
        </SearchContext.Provider>
    )
}

export function useSearch() {
    return useContext(SearchContext);
}

export function useSearchDispatch() {
    return useContext(SearchDispatchContext);
}

const searchReducer = (search, action) => {
    switch (action.type) {
        case 'query': {
            return search;
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
