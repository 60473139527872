import { storyblokEditable } from '@storyblok/react'
import Image from '@/Components/Image'
import Video from '@/Components/Video'

export default ({ blok }) => {
	const types = ['mp4','avi','mkv'];
  return (
    <div className="my-8 flex gap-4 max-w-full stories-grid"
       {...storyblokEditable(blok)}
    >
        {blok.images.map(image => (
          <div key={image.id}>
	          {
	          	types.indexOf(image.filename.split('.').reverse()[0]) < 0 ?
			          <Image image={image} /> :
			          <Video video={image} />
	          }
          </div>
        ))}
    </div>
  );
};
