import { storyblokEditable } from '@storyblok/react'
import Image from '@/Components/Image'
import Video from '@/Components/Video'

export default ({ blok }) => {
	let assets = [];
	if (blok.Image && blok.Image.id) assets.push(blok.Image);
	if (blok.Image_2 && blok.Image_2.id) assets.push(blok.Image_2);
	if (blok.Image_3 && blok.Image_3.id) assets.push(blok.Image_3);

	let links = [];
	if (blok.Link && blok.Link.cached_url) links.push(blok.Link);
	if (blok.Link_2 && blok.Link_2.cached_url) links.push(blok.Link_2);
	if (blok.Link_3 && blok.Link_3.cached_url) links.push(blok.Link_3);

	const types = ['mp4','avi','mkv'];

	const getAsset = (item) => {
		return (types.indexOf(item.filename.split('.').reverse()[0]) < 0 ?
			<Image image={item} /> :
			<Video video={item} />
		)
	}

	return (
		<div className="my-8 flex gap-4 max-w-full stories-grid"
		     {...storyblokEditable(blok)}
		>
			{assets.map((item,index) => (
				<div key={item.id}>
					{
						links[index] ?
							<a href={links[index].cached_url}>{getAsset(item)}</a> :
							getAsset(item)
					}
				</div>
			))}
		</div>
	);
};
