import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

const CategoryGrid = ({ blok }) => {
    return (
        <section className="home-blog slider"
                 {...storyblokEditable(blok)}
        >
            <div className="section-full">
                <ul className={"home-blog-list " + blok.class}>
                    {blok.categories.map((blok) => (
                        <StoryblokComponent blok={blok} key={blok._uid} />
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default CategoryGrid;
