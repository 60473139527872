import { storyblokEditable } from '@storyblok/react'

export default ({ blok }) => {
    return (
        <div className="gray-block" {...storyblokEditable(blok)}>
            <h5 className="gray-subtitle">{blok.title}</h5>
            <p className="gray-text">{blok.text}</p>
        </div>
    )
}
